import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'

interface IContent {
  heading?: string
  paragraph?: string
  imgDesktop?: string
  imgMobile?: string
  alt?: string
  content?: any
  logo?: string
  altLogo?: string
}
const CaseStudyContent = ({
  heading,
  paragraph,
  imgDesktop,
  imgMobile,
  alt,
  content,
  logo,
  altLogo,
}: IContent) => {
  return (
    <Container>
      {/* {logo && <Logo src={logo} alt={altLogo} />} */}
      {heading && <h6>{heading}</h6>}
      {paragraph && <p>{paragraph}</p>}
      {imgDesktop && (
        <>
          <Image src={imgDesktop} alt={alt} />{' '}
          <ImgMobile src={imgMobile} alt={alt} />
        </>
      )}
      {content && content}
    </Container>
  )
}

export default CaseStudyContent

const Container = styled.div`
  padding: 50px 0;

  @media (min-width: 1024px) {
    padding: 3rem 0 8rem;
  }

  & h6 {
    font-weight: bold;
  }

  & p {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  & li {
    color: ${theme.text.greyLight};
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;

    @media (min-width: 768px) {
      font-size: 1.6rem;
      line-height: 2.6rem;
    }

    &::before {
      height: 15px;
      width: 15px;
      min-height: 15px;
      min-width: 15px;
    }
  }

  & ul {
    margin-bottom: 20px;
  }
`
const Image = styled.img`
  display: none;
  @media (min-width: 768px) {
    display: block;
    margin: 40px 0 48px;
  }
`

const ImgMobile = styled.img`
  margin-bottom: 20px;
  @media (min-width: 768px) {
    display: none;
  }
`
const Logo = styled.img`
  width: 120px;
  margin-bottom: 46px;
`
