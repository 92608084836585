import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import img from '../images/industry-sector/team_industry.svg'
import IndustryTemplateLayout from '../components/IndustrySector/IndustryTemplateLayout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import IndustryLeftCol from '../components/IndustrySector/IndustryLeftCol'
import IndustryRightCol from '../components/IndustrySector/IndustryRightCol'
import IndustryDescription from '../components/IndustrySector/IndustryDescription'
import logo from '../images/homepage/popin-anim/retailer.svg'
import CaseStudyContent from '../components/CaseStudy/CaseStudyContent'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Case Studies',
    url: '/case-studies',
  },
  {
    title: 'Wesley James Ltd',
    url: '/wesley-james-ltd',
  },
]

const Beaumont = () => {
  return (
    <Layout
      activeLink="/case-studies"
      title="R&D Wesley James Ltd"
      description="R&D Wesley James Ltd"
    >
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <IndustryTemplateLayout>
        <IndustryLeftCol
          category="Case Studies"
          title="Wesley James Ltd"
          backLink="/case-studies"
        />
        <IndustryRightCol>
          <IndustryDescription title="Wesley James Ltd" />
          <CaseStudyContent
            heading="Accountancy"
            paragraph='I was recommend to use RDA by a professional colleague and they have provided an exceptional level of service and knowledge. Compared to other competitors they are by far to easiest for my clients to work with and I would 100% recommend them to anyone looking for assistance on an R&D claim.'
            // imgDesktop={img}
            // imgMobile={img}
            alt="Aeroplane"
            logo={logo}
            content={[
              <div>
                {/* <p>
                  The professionalism, knowledge and care applied in relation to
                  all of the R&D Tax Credit claims we have submitted to date is
                  quite simply second to none.
                </p>
                <p>
                  We do not hesitate to recommend RDA to any of our
                  clients for first-class advice and assistance for their R&D
                  Tax Credit claims."
                </p>
                <h6>Ivan Stanmore – FCCA, Director</h6> */}
              </div>,
            ]}
          />
        </IndustryRightCol>
      </IndustryTemplateLayout>
      <PromotionBanner />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default Beaumont
